export default {
  transparent: 'transparent',
  black: '#171C29',
  white: '#FFF',
  text: '#1B1B1B',
  primary: {
    lightest: '#e5f8ff',
    lighter: '#9BCEE4',
    light: '#3d9ff3',
    default: '#2195F3',
    dark: '#1584de',
    darker: '#08599a',
    darkest: '#05487e',
  },
  secondary: {
    lightest: '#e0ada4',
    lighter: '#e17b69',
    light: '#e16049',
    default: '#E25941',
    dark: '#c44731',
    darker: '#8f3322',
    darkest: '#652316',
  },
  accent: {
    light: '#FFECE9',
    default: '#FED5CE',
    dark: '#6E2D74',
  },
  gray: {
    50: '#F6F7FC',
    100: '#F1F3F8',
    200: '#E7EAF0',
    300: '#DDE1EA',
    400: '#CBCED7',
    500: '#B1B5C3',
    600: '#767C8D',
    700: '#5D6375',
    800: '#3E3F40',
    900: '#171C29',
  },
  success: {
    lightest: '#C9FBCE',
    lighter: '#A7EBC2',
    light: '#98CFD0',
    default: '#089B81',
    dark: '#065546',
    darker: '#10542B',
  },
  warning: {
    lightest: '#FFF3D3',
    lighter: '#CD9701',
    light: '#AA5A2D',
    default: '#AA5A2D',
    dark: '#AA5A2D',
    darker: '#AA5A2D',
    darkest: '#AA5A2D',
  },
  danger: {
    lightest: '#FEDEDE',
    lighter: '#F2C8D7',
    light: '#FAB637',
    default: '#CA3A31',
    dark: '#C9235F',
    darker: '#A82727',
    darkest: '#853423',
  },
  score: {
    great: {
      light: '#85ff99',
      default: '#20B174',
      dark: '#3b7745',
    },
    good: {
      light: '#ffa26e',
      default: '#FD8F56',
      dark: '#c44401',
    },
    bad: {
      light: '#da859a',
      default: '#E25941',
      dark: '#c03b5a',
    },
  },
}
